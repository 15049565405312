<template>
	<main class="main">
		<section class="container" v-if="blocks">
			<div class="main__box">
				<h1 class="main__title">{{ blocks[0].title }}</h1>
				<div class="main__text-box main__text-box--full">
					<p class="main__text">
						<span class="main__image-float-box">
							<img
								class="main__image-float main__image-float--operator"
								:src="require('@/assets/images/logos/operator-logo.png')"
								:alt="blocks[0].title"
								aria-hidden="true"
							/>
						</span>

						<span v-html="blocks[0].content"></span>
					</p>

          <div class="main__text main__list-operator">
						<ul>
              <li>ООО «САПФИР-ЭКСПЕРТ»</li>
							<li>
								Юр. адрес: 620026, г. Екатеринбург, ул. Гоголя, 36, оф. 402
							</li>
							<li>
								Почтовый адрес: 620026, г. Екатеринбург ул. Гоголя, д. 36 оф.
								900
							</li>
							<li>
								Телефон:
								<a style="color:#58B0DE;" href="tel:83433790886"
									>8 (343) 379 08 86</a
								>
							</li>
							<li>
								Электронная почта:
								<a style="color:#58B0DE;" href="mailto:info@eskso.ru"
									>info@eskso.ru</a
								>
							</li>
            </ul>
          </div>
				</div>
			</div>
		</section>

		<section class="main__box bg--gray" v-if="blocks">
			<div class="container">
				<h2 class="main__title">
					{{ blocks[1].title }}
				</h2>
				<div
					class="editor editor--list-blue editor--list-column-2"
					v-html="blocks[1].content"
				></div>
			</div>
		</section>

		<section class="container">
			<div class="main__box">
				<h2 class="main__title">
					СМИ о Единой социальной карте
				</h2>
				<ul class="grid">
					<li
						class="grid__item"
						v-for="(item, index) in massMedia"
						:key="index"
					>
						<a :href="item.link" target="_blank" class="grid__link">
							<div class="grid__logo-box">
								<img
									:src="item.logo"
									:alt="item.name"
									class="grid__logo grid__logo--big"
								/>
							</div>
							<div class="grid__content">
								<p class="grid__text">
									{{ item.text }}
								</p>
							</div>
						</a>
					</li>
				</ul>
			</div>
		</section>

		<section class="main__box bg--gray" v-if="blocks">
			<div class="container">
				<h2 class="main__title">
					{{ blocks[2].title }}
				</h2>
				<div
					class="editor editor--list-blue editor--list-column-3"
					v-html="blocks[2].content"
				></div>
			</div>
		</section>

		<section class="main__box">
			<div class="container">
				<h3 class="main__title">Последние новости</h3>
			</div>
			<SliderNews></SliderNews>
		</section>
	</main>
</template>

<script>
import SliderNews from '@/components/SliderNews.vue'

export default {
	name: 'OperatorInformation',

	components: {
		SliderNews,
	},

	data() {
		return {
			massMedia: [
				{
					link: 'https://www.oblgazeta.ru/society/auto/106357/',
					logo: require('@/assets/images/logos/logo_oblgazeta.png'),
					name: 'Облгазета',
					text: 'На Среднем Урале презентовали дизайн Единой социальной карты ',
				},
				{
					link: 'https://tass.ru/ural-news/6723187',
					logo: require('@/assets/images/logos/logo_tass.png'),
					name: 'Тасс',
					text:
						'Единая социальная карта в Свердловской области упростит получение более 140 выплат',
				},
				{
					link: 'https://www.kommersant.ru/doc/4163091',
					logo: require('@/assets/images/logos/logo_kommersant.svg'),
					name: 'Коммерсантъ',
					text: 'Свердловские власти выбрали оператора Единой социальной карты',
				},
				{
					link:
						'https://veved.ru/news/138011-v-sverdlovskoj-oblasti-poyavitsya-edinaya-socialnaya-karta.html',
					logo: require('@/assets/images/logos/logo_veved.png'),
					name: 'Вечерние ведомости',
					text: 'В Свердловской области появится Единая социальная карта',
				},
			],
		}
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})
	},
}
</script>
